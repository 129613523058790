@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap);
/*General CSS*/

h4{
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

h5{
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: 15px;
}

h6{
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: 15px;
}

h1, h2{
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: #ffffff;
}

h3{
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  letter-spacing: 1.2px;
  color: #007c34;
  margin: 0;
}

* {
  box-sizing: border-box;
}

*:before, *:after {
  box-sizing: border-box;
}

h1{
  margin: 0px 0px 0px 0px;
}

img{
  max-width:100%;
}

h1{
  font-size: 30px;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

/*Header CSS*/

.header {
  background-color: #f5683f;
  color: #ffffff;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header_restaurant {
  background-color: #f5683f;
  color: #ffffff;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header_restaurant_modified {
  background-color: #ececec;
  color: #000000;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.header_flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  align-content: stretch;
}

.header_flex_modified{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  align-content: stretch;
}

.icons_right{
  display: flex;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 300px) {
  .icons_right{
    display: none;
  }
}

.info_icon{
  margin-right: 10px;
}

.icon_link{
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_link_right{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

/*Footer CSS*/

.logo_align{
  display: flex;
  align-items: center;
}

footer {
  background-color: #f5683f;
  color: #000;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px;
  height: 100px;
  z-index: 900;
}

.footer_restaurant{

}

@media(max-width: 402px)
{
  footer
  {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.footer_menu{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer_h4:hover{
  text-decoration: underline;
}

.neural_solutions:hover{
  text-decoration: underline;
}

.copyright_h6{
  margin: 0px;
  padding-top: 10px;
  width: 100%;
  text-align: left;
}

/*Body CSS*/

html, body {
  height: 100%;
  position: relative;
  margin: 0px
}

.App {
  text-align: center;
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.body {
  font-size: calc(10px + 2vmin);
  font-family: 'Nunito', sans-serif;
  min-height: 100%;
  position: relative;
  margin: 0px 0px 60px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 80px;
}

.body_restaurant{
  font-size: calc(10px + 2vmin);
  font-family: 'Nunito', sans-serif;
  min-height: 100%;
  position: relative;
  margin: 0px 0px 60px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 40px;
}

.body_flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}

#bigFontSizeForSearch{
  font-size: 22px !important;
  color: black;
  text-decoration: none;
}

.body_centre{
  margin: auto;
  padding-top: 10vh;
}

.restaurant_box{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
  border-radius: 6px;
  margin-top: 30px;
  overflow: hidden;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.restaurant_image{
  height: 200px;
}

.restaurant_box_bottom{
  padding: 15px;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%
}

.qr_fixed{
  width: 70px;
  height: 70px;
  z-index: 1000;
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: #f385b5;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.restaurant_bottom_icon{
  width: 70px;
  height: 70px;
  background-color: #ffffff;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  -webkit-animation: MoveUpDown 2s linear infinite;
          animation: MoveUpDown 2s linear infinite;
}

@-webkit-keyframes MoveUpDown {
  0%, 50%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

@keyframes MoveUpDown {
  0%, 50%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

.qr_fixed_restaurant{
  width: 70px;
  height: 70px;
  z-index: 500;
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: #f385b5;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr_image{
  width: 47px;
  height: 47px;
}

.restaurant_bottom_bar{
  width: 100%;
  background-color: #ececec;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
  height: 90px;
  z-index: 500;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  flex-direction: row;
}

.icon_arrow{
  color: #007c34;
}

.icon_arrow_restaurant{
  color: #000000;
  margin-left: 10px;
}

.menu_item{
  display: flex;
  flex-wrap: nowrap;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  justify-content: space-between;
  max-width: 1000px;
  margin-bottom: 50px;
}

.menu_item_checkout{
  display: flex;
  flex-wrap: nowrap;
  margin-left: 10px;
  margin-right: 10px;
  border-left: 2px solid #ececec;
  padding-left: 20px;
  margin-bottom: 40px;
  width: 100%;
}

.category_scroller{
  padding-top: 10px;
  background-color: #ececec;
  margin-bottom: 10px;
}

.divider {
  height: 1.5px;
  background-color: #ececec;
}

.divider_accordion{
  height: 1.5px;
  background-color: #ececec;
  width: 100%;
}

.divider_food_item{
  height: 1.5px;
  background-color: #ececec;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.food_items_group{
  margin-left: 20px;
}

.food_items{
  margin-top: 15px;
}

.quantity_picker{
  margin-top: calc(15px - 0.6rem);
  margin-left: 11px;
}

.quantity_picker_checkout{
  margin-top: 0px;
  margin-left: 0px;
}

.h3_restaurant{
  color: #007c34;;
  text-align: left;
}

.h3_restaurant_discounted{
  color: black;
  text-align: left;
  text-decoration: line-through;
  margin-right: 4px;
}

.h3_restaurant_footer{
  color: black;
  text-align: left;
}

.h4_restaurant{
  color: black;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}



.h4_restaurant_less_padding{
  color: black;
  font-size: 20px;
  padding-top: 10px;
}

.h5_restaurant{
  color: grey;
  text-align: left;
  font-size: 14px;
  padding-top: 0px;
  padding-bottom: 15px;
}

.menu_item_picture{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 200px;
  width: 100%;
  min-width: 150px;
  justify-content: center;
}

.menu_item_description{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 10px;
  width: 100%;
}

.icon_category{
  max-height: 40px;
}

.qr_body{
  height: 100vh;
  background-color: black;
  position: relative;
}

.qr_main{
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  width: 90%;
}

.qr_reader_screen{
  padding: 10px;
  background:
          linear-gradient(to right, white 4px, transparent 4px) 0 0,
          linear-gradient(to right, white 4px, transparent 4px) 0 100%,
          linear-gradient(to left, white 4px, transparent 4px) 100% 0,
          linear-gradient(to left, white 4px, transparent 4px) 100% 100%,
          linear-gradient(to bottom, white 4px, transparent 4px) 0 0,
          linear-gradient(to bottom, white 4px, transparent 4px) 100% 0,
          linear-gradient(to top, white 4px, transparent 4px) 0 100%,
          linear-gradient(to top, white 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.contact_info_section{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.contact_icon{
  margin-right: 10px;
}

.form_field_div{
  margin-bottom: 20px;
}

.form_field{
  border: 2px solid black;
  border-radius: 3px;
  color: black;
  width: 75%;
  max-width: 500px;
  min-width: 200px;
  padding: 10px;
  font-family: 'Nunito', sans-serif;
}

.form_button{
  padding: 7px;
  width: 150px;
  background-color: #007c34;
  border-radius: 3px;
  color: white;
  font-family: 'Nunito', sans-serif;
}

.form_submitted{
  background-color: #f385b5;
  padding: 10px;
  margin: 0px 20px 0px 20px;
}

.restaurant_name{
  text-align: left;
  padding-right: 10px;
}

.qr_text{
  color: white;
  margin-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.item_name_modal{
  font-size: 30px;
  text-align: left;
}

.MuiBottomNavigationAction-label
{
  font-size: 15px !important;
}

.MuiFormControlLabel-label, .MuiButton-label{
  color: black !important;
  font-size: 15px !important;
  padding-left: 4px !important;
  font-family: 'Nunito', sans-serif !important;
  letter-spacing: 1.2px !important;
}

.quantity_picker input{
  color: black;
  font-family: 'Nunito', sans-serif;
}

.item_body{
  padding: 16px 16px 100px 16px;
}

.add_to_order{
  margin-top: 30px;
}

.add_to_order_checkout{
  margin-top: 10px;
}

.cart_icon{
  width: 47px;
  height: 47px;
}

.restaurant_bottom_icon_cart{
  width: 70px;
  height: 70px;
  background-color: #ffffff;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart_items_counter{
  position: absolute;
  bottom: 55px;
  right: 15px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart_items_counter_number{
  margin: 0px;
  padding: 0px;
  color: white;
  font-size: 18px;
}

.previous_orders_counter{
  position: absolute;
  bottom: 7px;
  right: 15px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 3px solid black;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previous_orders_counter_number{
  margin: 0px;
  padding: 0px;
  color: black;
  font-size: 18px;
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--inner-wrapper{
  justify-content: center;
}

.react-horizontal-scrolling-menu--scroll-container{
  width: auto !important;
}

